<template>
  <!-- 使用oss上传 -->
  <div>
    <el-form>
      <el-upload
        :limit="limit"
        action
        accept="image/*"
        :on-change="uploadFile"
        list-type="picture-card"
        :auto-upload="false"
        :file-list="fileList"
        :on-exceed="handleExceed"
        :on-remove="handleRemove"
        :on-preview="handlePreview"
        ref="upload"
        class="avatar-uploader"
        :disabled="disabled"
        multiple
      >
        <i class="el-icon-plus"></i>
      </el-upload>
    </el-form>
  </div>
</template>

<script>
//引入上传图片接口
import { upload } from "@/utils/alioss";
import {
  compressImageToMaxSize,
} from "@/utils/compressionUtils.js"; //引入图片压缩
export default {
  name: "my-upload",
  props: {
    limit: Number,
    fileList: Array,
    disabled: Boolean,
  },
  data() {
    return {
      dialogVisible: false, //查看图片弹出框
      imgUrl: "", //上传图片后地址合集
    };
  },
  //监听上传图片的数组(为了处理修改时,自动渲染问题,和上传按钮消失问题);
  // watch: {
  //   list: {
  //     deep: true,
  //     handler(newName) {
  //       console.log(newName, "newName12312");
  //       if (newName.length == this.limit) this.showUpload = true;
  //       else this.showUpload = false;
  //     },
  //   },
  // },
  methods: {
    //文件列表移除文件时的函数
    handleRemove(file) {
      const index = this.fileList.findIndex((item) => item.uid === file.uid);
      this.$emit("delUrl", index);
    },
    //点击文件列表中已上传的文件时的函数
    handlePictureCardPreview(file) {
      this.imgUrl = file.url;
      this.dialogVisible = true;
    },
    //这里是不需要直接上传而是通过按钮上传的方法
    submitUpload() {
      this.$refs.upload.submit();
    },
    //文件状态改变时的函数(主要逻辑函数)
    async uploadFile(e) {
      const originalFileName = e.name;
      // 获取文件扩展名
      const fileExtension =
        originalFileName.lastIndexOf(".") !== -1
          ? originalFileName.slice(originalFileName.lastIndexOf("."))
          : "";
      // const file = e.file; <- 这里是不需要直接上传而是通过按钮上传的
      let file = e.raw; // <- 这里是直接上传的
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0"); // 月份从0开始，需要加1
      const day = String(now.getDate()).padStart(2, "0");
      const name = `public/dazhou-web/images/admin/${year}-${month}-${day}/${Date.now()}${fileExtension}`;
      //大小
      const size2 = file.size / 1024 / 1024 < 2;
      const size20 = file.size / 1024 / 1024 < 20;
      if (
        !(
          file.type === "image/png" ||
          file.type === "image/gif" ||
          file.type === "image/jpg" ||
          file.type === "image/jpeg"
        )
      ) {
        this.$message.warning({
          title: "警告",
          message:
            "请上传格式为image/png, image/gif, image/jpg, image/jpeg的图片",
        });
        this.$refs.upload.clearFiles();
        return;
      }
      if (!size2) {
        console.log("大于2M,小于10m");
        //大于2M走压缩逻辑
        file = await compressImageToMaxSize(file);
      }
      if (!size20) {
        this.$message.warning({
          title: "警告",
          message: "图片大小必须小于20M",
        });
        this.$refs.upload.clearFiles();
        return;
      }
      if (this.limit == 1) this.imgUrl = ""; //此处判断为一张的时候需要清空数组
      upload(name, file).then((res) => {
        //这里返回的数据结构(根据自己返回结构进行修改)
        if (res.res.statusCode === 200) {
          this.$message.success("上传成功");
          this.imgUrl = `https://oss.028yida.com/${res.name}`;
          //调用父组件的方法来传递图片参数
          this.$emit("getUrl", this.imgUrl);
        } else {
          this.imgUrl = "";
          this.$refs.upload.clearFiles();
          this.$message.error("上传失败");
        }
      });
    },
    // 预览图片
    handlePreview(file) {
      if (file.url) {
        window.open(file.url, "_blank"); // 在新标签页中打开文件
      } else {
        this.$message.warning("文件链接不存在");
      }
    },
    //文件超出个数限制时的函数
    handleExceed() {
      this.$message.info(`最多只允许上传${this.limit}张图片`);
    },
  },
};
</script>

<style scope>
.hide .el-upload--picture-card {
  display: none !important;
}
.avatar-uploader > .el-upload {
  width: 80px;
  height: 80px;
  line-height: 80px;
  border-radius: 0px;
  background: #fff;
  border: 1px dashed #ccc;
}
.avatar-uploader > .el-upload > i {
  font-size: 28px;
  color: #ccc;
}

.el-icon-delete {
  font-size: 20px;
}

.avatar-uploader > .el-upload-list > .el-upload-list__item > img {
  width: 80px;
  height: 80px;
  border-radius: 0px;
}

.el-upload-list--picture-card .el-upload-list__item {
  margin-right: 10px;
  width: 80px !important;
  height: 80px;
}
</style>
